<template>
  <div v-if="!loading">
    <!-- Freestyle Role -->
    <div v-if="role === 'freestyle'">
      <v-textarea
        v-model="freestyleText"
        filled
        :label="$t('labelFreestyle')"
        auto-grow
        hide-details
        style="border-radius: 12px"
        @change="setRole"
        data-cy="persona-freestyle-input"
      ></v-textarea>
    </div>

    <!-- AI Tutor Role -->
    <div v-if="role === 'tutor'">
      <v-textarea
        v-model="tutorGoals"
        filled
        :label="$t('labelTutorGoals')"
        hide-details
        auto-grow
        rows="2"
        style="border-radius: 12px"
        @change="setRole"
        data-cy="persona-tutor-goals-input"
      ></v-textarea>

      <div class="my-4"></div>

      <v-textarea
        v-model="tutorInstructions"
        filled
        :label="$t('labelTutorInstructions')"
        rows="2"
        auto-grow
        hide-details
        style="border-radius: 12px"
        @change="setRole"
        data-cy="persona-tutor-instructions-input"
      ></v-textarea>

      <div class="my-4"></div>

      <v-textarea
        v-model="tutorPersonalization"
        filled
        :label="$t('labelTutorPersonalization')"
        rows="2"
        auto-grow
        hide-details
        style="border-radius: 12px"
        @change="setRole"
        data-cy="persona-tutor-personalization-input"
      ></v-textarea>
    </div>

    <!-- AI Assistant Role -->
    <div v-if="role === 'assistant'">
      <v-textarea
        v-model="assistantGoals"
        filled
        :label="$t('labelAssistantGoals')"
        rows="2"
        hide-details
        style="border-radius: 12px"
        @change="setRole"
        data-cy="persona-assistant-goals-input"
      ></v-textarea>

      <div class="my-4"></div>

      <v-textarea
        v-model="assistantInstructions"
        filled
        :label="$t('labelAssistantInstructions')"
        rows="2"
        hide-details
        style="border-radius: 12px"
        @change="setRole"
        data-cy="persona-assistant-instructions-input"
      ></v-textarea>

      <div class="my-4"></div>

      <v-textarea
        v-model="assistantConstraints"
        filled
        :label="$t('labelAssistantConstraints')"
        rows="2"
        hide-details
        style="border-radius: 12px"
        @change="setRole"
        data-cy="persona-assistant-constraints-input"
      ></v-textarea>
    </div>

    <!-- AI Companion Role -->
    <div v-if="role === 'companion'">
      <v-textarea
        v-model="companionStory"
        filled
        :label="$t('labelCompanionStory')"
        rows="2"
        hide-details
        style="border-radius: 12px"
        @change="setRole"
        data-cy="persona-companion-story-input"
      ></v-textarea>

      <div class="my-4"></div>

      <v-textarea
        v-model="companionContext"
        filled
        :label="$t('labelCompanionContext')"
        rows="2"
        hide-details
        style="border-radius: 12px"
        @change="setRole"
        data-cy="persona-companion-context-input"
      ></v-textarea>           
    </div>
  </div>
</template>

<script>
export default {
  props: {
    role: {
      type: String,
      default: ''
    },
    currentRole: {
      type: Object,
      default: () => ({})
    }
  },

  created() {
    // Update data fields based on currentRole
    if (this.currentRole.role) {
      switch (this.currentRole.role) {
        case 'tutor':
          this.tutorGoals = this.currentRole.goals || '';
          this.tutorInstructions = this.currentRole.instructions || '';
          this.tutorPersonalization = this.currentRole.personalization || '';
          break;
        case 'assistant':
          this.assistantGoals = this.currentRole.goals || '';
          this.assistantInstructions = this.currentRole.instructions || '';
          this.assistantConstraints = this.currentRole.constraints || '';
          break;
        case 'companion':
          this.companionStory = this.currentRole.story || '';
          this.companionContext = this.currentRole.context || '';
          break;
        case 'freestyle':
          this.freestyleText = this.currentRole.text || '';
          break;
      }
    }
  },

  watch: {
    role() {
      /*
      * Freestyle textarea has auto-grow and no rows set
      * Tutor textarea has auto-grow and rows set to 2.
      * When the role variable changes from Freestyle to Tutor
      * the Tutor textarea keeps the height of the Freestyle
      * This loading forces the component to refresh, thus solving the issue.
      */
      this.loading = true
      setTimeout(() => {
        this.loading = false
      }, 10)
    }
  },

  data() {
    return {
      loading: false,
      freestyleText: '',
      tutorGoals: '',
      tutorInstructions: '',
      tutorPersonalization: '',
      assistantGoals: '',
      assistantInstructions: '',
      assistantConstraints: '',
      companionStory: '',
      companionContext: ''
    };
  },

  computed: {
    roleData() {
      switch (this.role) {
        case 'tutor':
          return {
            role: 'tutor',
            goals: this.tutorGoals,
            instructions: this.tutorInstructions,
            personalization: this.tutorPersonalization,
          };
        case 'assistant':
          return {
            role: 'assistant',
            goals: this.assistantGoals,
            instructions: this.assistantInstructions,
            constraints: this.assistantConstraints,
          };
        case 'companion':
          return {
            role: 'companion',
            story: this.companionStory,
            context: this.companionContext,
          };
        case 'freestyle':
          return {
            role: 'freestyle',
            text: this.freestyleText,
          };
        default:
          return {};
      }
    },
  },

  methods: {
    setRole() {
      this.$emit('setRoles', this.roleData)
    }
  }
}
</script>