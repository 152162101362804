<template>
  <v-row justify="start">
    <v-col class="py-0 px-1">
      <SnackBar
        :snackMsg="snackMsg"
        :snackOn="snackOn"
        @close="snackOn = false"
      />

      <LoadingSnack
        :message="loadingMsg"
        :snackOn="loadingSnack"
        @close="loadingSnack = false"
      />

      <v-card
        outlined
        rounded="xl"
        :color="navColor"
        class="pb-4"
        :style="`height: 100%; width: 100%; border: ${borderStyle}`"
        @keydown="onKeyDown"
      >
        <HeaderCreate
          :create="true"
          :loading="loading"
          @save="save"
        ></HeaderCreate>

        <input
          ref="avatarInput"
          type="file"
          accept=".png,.jpg,.jpeg,.gif,.svg"
          @change="addAvatar"
        />
        <ImageCropper
          stencilComponent="CircleStencil"
          :imageFile="persona.imageFile"
          :mimeType="persona.mimeType"
          :cropperOn="cropperOn"
          @closeDialog="cropperOn = false"
          @save="saveAvatar"
        />

        <v-container class="py-0">
          <Scrollbar
            :flexible="true"
            :thin="true"
            :style="`height: ${rootHeight}px;`"
            class="px-3"
          >
            <v-form ref="form" class="height100">
              <v-row
                justify="start"
                align="start"
                class="px-3 height100"
              >
                <v-col cols="12" class="py-0">
                  <v-list-item
                    three-line
                    class="px-0"
                  >
                    <v-list-item-avatar
                      rounded="xl"
                      size="160"
                      :class="{
                        'grey lighten-2': !$vuetify.theme.dark,
                        'grey darken-4': $vuetify.theme.dark
                      }"
                      @click="setAvatarInput"
                      data-cy="persona-avatar-button"
                    >
                      <v-hover v-slot:default="{ hover }">
                        <v-img :src="persona.avatar">
                          <HoverLayer v-if="hover || !persona.avatar">
                            <v-row
                              justify="center"
                              align="center"
                            >
                              <v-icon
                                dark
                                large
                              >
                                {{ mdiImage }}
                              </v-icon>
                            </v-row>
                          </HoverLayer>
                        </v-img>
                      </v-hover>
                    </v-list-item-avatar>
                    
                    <v-list-item-content>
                      <v-container class="py-0 pr-0">
                        <v-row>
                          <v-col :xs="12" :sm="12" :md="showUsernameField ? 6 : 12">
                            <v-text-field
                              v-model="persona.name"
                              :label="$t('labelName')"
                              style="border-radius: 15px;"
                              filled
                              hide-details
                              :rules="[v => !!v && v.length > 3]"
                              data-cy="persona-name-input"
                              @blur="onNameBlur"
                            />
                          </v-col>
                          <v-col v-if="showUsernameField" :xs="12" :sm="12" :md="6">
                            <v-text-field
                              v-model="persona.username"
                              label="Username"
                              style="border-radius: 15px;"
                              filled
                              hide-details
                              :rules="[v => !!v && v.length > 3]"
                              data-cy="persona-username-input"
                            />
                          </v-col>
                          <v-col cols="12">
                            <v-textarea
                              v-model="persona.description"
                              :label="$t('labelDescription')"
                              style="border-radius: 15px;"
                              hide-details
                              rows="3"
                              filled
                              dense
                              :rules="[v => !!v && v.length > 10]"
                              data-cy="persona-description-input"
                            />
                          </v-col>
                        </v-row>
                      </v-container>
                    </v-list-item-content>
                  </v-list-item>

                  <div class="my-2"></div>

                  <v-text-field
                    v-model="persona.caption"
                    filled
                    hide-details
                    :label="$t('labelCaption')"
                    style="border-radius: 12px"
                    data-cy="persona-caption-input"
                  ></v-text-field>

                  <div class="my-4"></div>

                  <div class="text-h5 px-1">
                    Greeting
                  </div>
                  <div :class="{
                    'text-body-2 grey--text px-1': true,
                    'text--darken-3': !$vuetify.theme.dark
                  }">
                    Customize how your persona should start conversations.
                  </div>

                  <div class="my-4"></div>

                  <v-textarea
                    v-model="persona.greeting"
                    :label="$t('labelGreeting')"
                    style="border-radius: 15px;"
                    :disabled="!persona.greetingOn"
                    hide-details
                    rows="3"
                    filled
                    dense
                    data-cy="persona-greeting-input"
                  />

                  <v-switch
                    v-model="persona.greetingOn"
                    inset
                    class="mx-2"
                    color="primary"
                    :label="`Greeting ${persona.greetingOn ? 'on' : 'off'}`"
                    data-cy="persona-greeting-switch"
                  />

                  <div class="my-4"></div>

                  <SelectSources
                    :defaultReference="referenceFolder"
                    :personaFolder="personaFolder"
                    :currentContents="currentContents"
                    @referenceFolder="referenceFolder = $event"
                    @addContentFiles="addContentFiles"
                  />

                  <div class="my-2"></div>

                  <div class="text-h5 px-1">
                    Tools
                  </div>
                  <div :class="{
                    'text-body-2 grey--text px-1': true,
                    'text--darken-3': !$vuetify.theme.dark
                  }">
                    Turbocharge your Persona with a suite of HiSolver Tools: web searching, chat with mathematical animations, generate questions, and much more.
                  </div>

                  <div class="my-2"></div>

                  <SelectTools
                    :persona="persona"
                    @setTools="setTools($event)"
                    @setParams="setParams"
                  />

                  <div class="my-4"></div>

                  <div class="text-h5 px-1">
                    Roles
                  </div>
                  <div :class="{
                    'text-body-2 grey--text px-1': true,
                    'text--darken-3': !$vuetify.theme.dark
                  }">
                    You can add multiple roles for your persona.
                    For example, your persona can be at some times a language teacher (tutor role),
                    and at others a movie character (companion role).
                  </div>

                  <div class="my-2"></div>

                  <div
                    v-for="(role, index) in persona.roles"
                    :key="`role_${index}`"
                    v-if="!loadingRoles"
                  >
                    <RoleCard
                      :index="index"
                      :lenRoles="persona.roles.length"
                      :currentRole="role"
                      @setRole="setRole($event, index)"
                      @addRole="addRoles"
                      @remove="removeRole(index)"
                    />

                    <div class="my-4"></div>
                  </div>

                  <v-card
                    outlined
                    style="border-radius: 12px; background-color: transparent;"
                  >
                    <v-container>
                      <PrivacySettings
                        :customObs="`* Obs: if Prompt Visibility is activated,
                          people will be able to see the persona prompts, tools, files and else.`"
                        :textSize="6"
                        :generative="true"
                        :currentSettings="currentSettings"
                        @settings="setSettings"
                      />
                    </v-container>
                  </v-card>

                  <div class="my-4"></div>

                  <v-btn
                    v-show="false"
                    large
                    elevation="0"
                    color="primary"
                    style="border-radius: 12px;"
                  >
                    advanced
                    <v-icon right>
                      {{ mdiChevronDown }}
                    </v-icon>
                  </v-btn>
                  <div style="height: 200px;"></div>
                </v-col>
              </v-row>
            </v-form>
          </Scrollbar>
        </v-container>
      </v-card>
    </v-col>
  </v-row>
</template>

<script>
import { getResourceUrl } from '@utils'
import { mapGetters, mapActions } from 'vuex'
import { Scrollbar, SnackBar, LoadingSnack } from '@components/App'
import { Select } from '@components'
import { HoverLayer } from '@components'
import HeaderCreate from '@components/Persona/HeaderCreate'
import RoleCard from '@components/Persona/RoleCard'
import SelectTools from '@components/Persona/SelectTools'
import SelectSources from '@components/Persona/SelectSources'
import { PrivacySettings } from '@components'
import { mdiChevronDown, mdiImage } from '@mdi/js'
import ImageCropper from '@components/Image/Cropper'
import { MediaURL } from '@components'
import ObjectID from 'bson-objectid'
import API from '@api'
import Langchain from '@langchain'
import store from '@store'
import PersonaService from '@api/persona'

export default {
  components: {
    RoleCard,
    Scrollbar,
    SnackBar,
    LoadingSnack,
    Select,
    HeaderCreate,
    HoverLayer,
    PrivacySettings,
    ImageCropper,
    SelectTools,
    SelectSources
  },

  unmounted() {
    this.initializePersona()
  },
  
  async created() {
    this.personaService = PersonaService(this.user.id)
    if (store.getters.formState.persona && !store.getters.formState.persona.id) {
      this.persona = store.getters.formState.persona
    }

    this.getMimeType = MediaURL.getMimeType.bind(this);
    this.getMediaUrl = MediaURL.getMediaUrl.bind(this);

    if (this.$route.name === 'persona.edit') {
      const persona = await API().get(`persona/${this.user.id}`, {
        params: {
          id: this.$route.query.id
        }
      })

      if (persona && persona.sender && persona.sender.profile === this.myProfileId) {
        this.loadingRoles = true
        // avoids two way binding
        this.persona = persona
        this.currentDescription = persona.description
        setTimeout(() => {
          this.loadingRoles = false
        })

        // in case the persona had no roles defined
        if (!this.persona.roles) this.persona.roles = [{}]

        this.persona.avatar = this.getResourceUrl(persona.avatar)

        // loads the persona folder
        const [personaFolder] = await API().get(`folders/${this.user.id}`, {
          params: {
            query: {
              sender: {
                $eq: this.myProfileId
              },
              persona: {
                $eq: persona._id
              }
            }
          }
        })

        if (personaFolder) {
          this.personaFolder = personaFolder

          let currentContents = await API().get(`contents/${this.user.id}`, {
            params: {
              query: {
                folder: {
                  $eq: personaFolder.id
                }
              },
              sort: {
                createdAt: -1
              }
            }
          })

          if (currentContents.length > 0) this.currentContents = currentContents

          const [folderConnector] = await API().get('connectors', {
            params: {
              query: {
                type: {
                  $eq: 'folder_sync'
                },
                entities: {
                  $elemMatch: {
                    ref: 'Persona',
                    _id: ObjectID(this.persona.id)
                  }
                }
              }
            }
          });

          if(folderConnector) {
            setTimeout(() => {
              this.referenceFolder = folderConnector.entities[0]._id
            }, 100)
          }
        }
      }

      // Toggles the autocomplete task for the persona greeting
      this.persona.greetingOn = Boolean(this.persona.greeting)
    }
    this.langchainService = Langchain();
  },

  data: () => ({
    mdiChevronDown,
    mdiImage,
    snackOn: false,
    snackMsg: '',
    loadingSnack: false, 
    loadingMsg: '',
    loadingRoles: false,
    loading: false,
    cropperOn: false,
    avatarObj: {
      mediaSrc: '',
      filename: '',
      type: ''
    },
    personaTag: 'All personas',
    persona: {
      name: '',
      username: '',
      description: '',
      greeting: '',
      caption: '',
      tools: [],
      roles: [{}],
      greetingOn: true,
      imageFile: {},
      mimeType: '',
      avatar: '',
    },
    referenceFolder: '',
    files: [],
    currentContents: [],
    personaFolder: null,
    currentDescription: '',
    langchainService: null,
  }),

  watch: {
    persona: {
      handler: function(value) {
        this.setFormState({
          ...this.formState,
          persona: value
        })
      },
      deep: true
    }
  },

  computed: {
    ...mapGetters({
      innerHeight: 'innerHeight',
      user: 'user',
      myName: 'profile/name',
      myUsername: 'profile/username',
      myAvatar: 'profile/avatar',
      myProfileId: 'profile/id',
      myFolders: 'folders/myFolders',
      currentPersona: 'currentPersona',
      roles: 'persona/roles',
      formState: 'formState',
    }),

    rootHeight() {
      return this.innerHeight * (1 - (100 / this.innerHeight)) - 64
    },

    currentSettings() {
      return {
        ...( 'isPublic' in this.persona ? { isPublic: this.persona.isPublic } : {} ),
        ...( 'isAnonymous' in this.persona ? { isAnonymous: this.persona.isAnonymous } : {} ),
        ...( 'isUnlisted' in this.persona ? { isUnlisted: this.persona.isUnlisted } : {} ),
        ...( 'visiblePrompt' in this.persona ? { visiblePrompt: this.persona.visiblePrompt } : {} )
      }
    },

    slicedText() {
      return this.personaTag.length > 15 ? `${this.personaTag.slice(0, 6)}...` : this.personaTag;
    },
    borderStyle() {
      return this.$vuetify.theme.dark
        ? 'transparent !important;'
        : 'thin solid rgba(0, 0, 0, 0.12) !important;'
    },
    navColor() {
      return !this.$vuetify.theme.dark
        ? '#F7F7F7'
        : ''
    },
    showUsernameField() {
      return this.$route.name !== 'persona.edit'
    }
  },

  methods: {
    ...mapActions({
      SET_PERSONA: 'SET_PERSONA',
      setFormState: 'setFormState'
    }),

    onNameBlur() {
      this.persona.username = this.persona.name.trim().toLowerCase().replace(/\s/g, '');
    },

    initializePersona() {
      this.persona = {
        name: '',
        description: '',
        greeting: '',
        caption: '',
        tools: [],
        roles: [{}],
        greetingOn: true,
        imageFile: {},
        mimeType: '',
        avatar: '',
      }
    },

    getResourceUrl,

    setParams(newParams) {
      this.persona.tools = this.persona.tools.map(tool => {
        let { params: oldParams, ...rest } = tool;
        return tool.type === 'question_gen' ?
          { ...rest, params: newParams } :
          tool
      })
    },

    setTools(tools) {
      this.persona.tools = tools
    },

    addContentFiles(contents) {
      this.files = this.files.concat(contents)
    },

    async onKeyDown(evt) {
      if (evt.key === 's' && (evt.metaKey || evt.ctrlKey)) {
        evt.preventDefault()
        this.save()
      }
    },

    setSettings(settings) {
      for (const key in settings) {
        if (settings.hasOwnProperty(key)) {
          this.persona[key] = settings[key]
        }
      }
    },

    addRoles() {
      this.loadingRoles = true
      this.persona.roles.unshift({})

      setTimeout(() => {
        this.loadingRoles = false
      })
    },

    removeRole(index) {
      this.loadingRoles = true
      this.persona.roles.splice(index, 1);

      setTimeout(() => {
        this.loadingRoles = false
      })
    },

    setRole(data, index) {
      this.persona.roles[index] = data
    },

    setAvatarInput() {
      this.$refs.avatarInput.click()
    },

    // transform this into a promise
    async addAvatarFromDalle(dalleImageUrl) {
      // dalleImageUrl is an object url already
      this.avatarObj.mediaSrc = dalleImageUrl
      // TODO: make the iamge name unique
      this.avatarObj.filename = this.persona.name + ".png";
      this.avatarObj.type = 'image/png';
      this.persona.avatar = this.avatarObj.mediaSrc;
    },

    async addAvatar(e) {
      const [imageFile] = e.target.files;
      const mimeType = await this.getMimeType(imageFile);

      if (mimeType === 'image/gif') {
        this.avatarObj.mediaSrc = URL.createObjectURL(imageFile);
        this.avatarObj.filename = imageFile.name
        this.avatarObj.type = 'image/gif'
        this.persona.avatar = this.avatarObj.mediaSrc
      } else {
        this.persona.mimeType = mimeType;
        this.persona.imageFile = imageFile;
        this.cropperOn = true;
      }
    },

    saveAvatar(imageFile) {
      this.cropperOn = false
      this.avatarObj.mediaSrc = URL.createObjectURL(imageFile)
      this.avatarObj.filename = imageFile.name
      this.avatarObj.type = imageFile.type
      this.persona.avatar = this.avatarObj.mediaSrc
    },

    toQueryString(obj) {
      // Function to convert an object to a query string
      return Object.keys(obj).map(key =>
        `${encodeURIComponent(key)}=${encodeURIComponent(obj[key])}`
      ).join(';');
    },

    async setPersonaTopics(coreDef) {
      
      const [questionGen] = this.persona.tools ?
        this.persona.tools.filter(({ type }) => type === 'question_gen') : []

      if (questionGen) {
        if (questionGen.params && questionGen.params.topics) return;

        const result = await this.langchainService.request(
          'hisolver_llm_call',
          {
            stream: false,
            username: this.myUsername,
            user_name: this.myName,
            assistant_name: 'Anna',
            query: `
              Consider the following definition for an AI Persona: """${JSON.stringify(coreDef)}""""

              I want you to return a string of topics that best capture this AI Persona's description/about, and the goals of the persona roles.
              
              The string will be used to craft questions in a question generator.
              GIVE ME JUST THE STRING OF TOPICS
            `,
            msg_type: 'chat_msg',
            model_number: process.env.NODE_ENV === 'production' ? 2 : 1
          }
        )

        this.persona.tools = this.persona.tools.map(tool => {
          if (tool.type === 'question_gen') {
            if (tool.params) {
              tool.params.topics = result
            } else {
              tool.params = { topics: result }
            }
          }

          return tool;
        })

        return true;
      } else return;
    },

    async savePersona(update = false, createFolder = false) {
      let avatar = this.persona.avatar;
      try {
        if (this.avatar != this.persona.avatar)
          [avatar] = await this.getMediaUrl([this.avatarObj], 'images/avatars')
      } catch (err) {
        console.log('err: ', err);
      }

      const sender = {
        name: this.myName,
        avatar,
        username: this.myUsername,
        avatar: this.myAvatar,
        profile: this.myProfileId
      }

      const data = {
        sender,
        avatar,
        tools: this.persona.tools,
        name: this.persona.name,
        username: this.persona.username,
        about: this.persona.about,
        description: this.persona.description,
        caption: this.persona.caption,
        greeting: this.persona.greeting,
        roles: this.persona.roles,
        //translation,
        ...this.currentSettings
      }

      let persona;
      if (update) {
        persona = await API().put(`persona/${this.user.id}`, data, {
          params: {
            id: this.persona.id
          }
        })
      } else {
        persona = await API().post(`persona/${this.user.id}`, data)
      }

      if (this.currentPersona.id === this.persona.id)
        this.SET_PERSONA(persona)

      if (createFolder) {
        // Sets the Persona Folder.
        const folder = await API().post(`folder/${this.user.id}`, {
          persona: persona._id,
          name: persona.name,
          sender: this.myProfileId
        })
        this.personaFolder = folder

        // TODO: create contents and attach them to folder

        if (this.referenceFolder) {
          // connects the reference folder
          await API().post('connector', {
            entities: [{
              _id: this.referenceFolder,
              ref: 'Folder',
              source: true
            }, {
              _id: folder._id,
              ref: 'Folder'
            }, {
              _id: this.persona.id,
              ref: 'Persona'
            }],
            type: 'folder_sync'
          })
        }
      }

      // adds the additional files to the persona folder
      if (this.files.length > 0 && this.personaFolder && this.personaFolder.id) { // additional files here
        API().put(`content/${this.user.id}`, {
          folder: this.personaFolder.id
        }, {
          params: {
            many: true,
            // note: you can only use more complex queries like unset
            // by NOT passing 'id', i.e., gotta pass _id
            // this is a shortcoming of the current backend model implementation
            _id:  {
              $in: this.files.map(file => file._id)
            }
          }
        })
      }

      this.initializePersona()

      if (!this.update) {
        this.$router.replace(`/personas?id=${persona._id}`)
      }
      this.loading = false

      this.setFormState({
        ...this.formState,
        persona: null
      })
    },

    async imageGen(promptText) {
      const { VUE_APP_LANGCHAIN_API } = process.env
      const response = await fetch(`${VUE_APP_LANGCHAIN_API}/generate_image?prompt=${encodeURIComponent(promptText)}`, {
        headers: { 'Content-Type': 'image/jpeg' } // Or the appropriate image MIME type your backend sends
      });

      if (!response.ok) {
        throw new Error('Network response was not ok: ' + response.statusText);
      }

      console.log("response: ", response)

      const blob = await response.blob();
      return URL.createObjectURL(blob);
    },

    hasRoles() {
      return Array.isArray(this.persona.roles) &&
        this.persona.roles.some(role => {
          return Object.keys(role).length > 0 &&
            Object.entries(role).some(([key, value]) => key != 'role' && value);
        });
    },

    async handleSaveRequirments() {
      const requirements = [];

      if (!this.persona.avatar) {
        requirements.push(
          async () => {
            try {
              const promptText = await this.langchainService.request(
              'hisolver_llm_call',
              {
                stream: false,
                username: this.myUsername,
                user_name: this.myName,
                assistant_name: 'Anna',
                query: `
                  Use the following information to craft a prompt that asks an AI model to generate an image. Your prompt should contains details of what you want in the image to be generated. Write a longer prompt, outlining the style you want to use, the setting, etc.

                  '''
                  name: ${this.persona.name}
                  description: ${this.persona.description}
                  roles: ${JSON.stringify(this.persona.roles)}
                  '''

                  Return only the prompt:
                `,
                model_number: process.env.NODE_ENV === 'production' ? 2 : 1
              })
              const imageGenerated = await this.imageGen(promptText)
              this.addAvatarFromDalle(imageGenerated)
            } catch (err) {
              console.log('Error generating image prompt: ', err);
            }
          }
        )
      }

      if (this.persona.description != this.currentDescription) {
        requirements.push(
          async () => {
            try {
              const about = await this.langchainService.request(
                'hisolver_tasks',
                {
                  query: this.persona.description,
                  model_number: 1,
                  task: 'transform_description'
                }
              )
              this.persona.about = about
            } catch (err) {
              console.log('Error transforming description: ', err);
            }
          }
        )
      }

      if (!this.persona.caption || this.persona.caption.length === 0) {
        requirements.push(
          async () => {
            try {
              const caption = await this.langchainService.request(
                'hisolver_tasks',
                {
                  query: `name (the name of an AI Persona): ${this.persona.name}
                          description (the description of the AI Persona): ${this.persona.description}
                          caption (an one-liner for the AI Persona in the first person): `,
                  model_number: 1,
                  task: 'autocomplete_field'
                }
              )
              this.persona.caption = caption
            } catch (err) {
              console.log('Error generating caption: ', err);
            }
          }
        )
      }

      if (this.persona.greeting && this.persona.greeting.length === 0 && this.persona.greetingOn) {
        requirements.push(
          async () => {
            try {
              const greeting = await this.langchainService.request(
                'hisolver_tasks',
                {
                  query: `name (the name of an AI Persona): ${this.persona.name}
                          description (the description of the AI Persona): ${this.persona.description}
                          greeting (how the persona should introduce itself to the users in their first conversation):`,
                  model_number: 1,
                  task: 'autocomplete_field'
                }
              )
              this.persona.greeting = greeting
            } catch (err) {
              console.log('Error generating greeting: ', err);
            }
          }
        )
      }

      await Promise.all(requirements.map(r => r()));
      await this.setPersonaTopics({
        name: this.persona.name,
        about: this.persona.about,
        description: this.persona.description,
        caption: this.persona.caption,
        greeting: this.persona.greeting,
      });
    },

    async isUsernameAvailable(username) {
      try {
        const usernameExist = await this.personaService.isUsernameAvailable(this.persona.username);
        return !!usernameExist
      } catch (error) {
        this.snackMsg = 'personaUsernameUsed'
        this.snackOn = true
        this.loadingMsg = ''
        this.loadingSnack = false
        this.loading = false
      }
      return false
    },

    async save() {
      const hasRoles = this.hasRoles()
      
      if (this.$refs.form.validate() && hasRoles) {
        this.loading = true

        this.loadingMsg = 'Saving persona...'
        this.loadingSnack = true

        if (this.$route.name !== 'persona.edit' && this.isUsernameAvailable()) return

        await this.handleSaveRequirments()

        if (this.$route.name === 'persona.edit') {
          // Update persona
          this.savePersona(true, !this.personaFolder)
        } else {
          // Create persona
          this.savePersona(false, true)
          this.loading = false
        }
      } else {
        if (hasRoles)
          this.snackMsg = 'rolesMissing'
        else if (this.persona.description.length < 10)
          this.snackMsg = 'shortDescription'
        else
          this.snackMsg = 'fieldMissing'
        this.snackOn = true
      }
    }
  }
}
</script>

<style>
.v-autocomplete__content.v-menu__content.theme--dark > .v-sheet {
  background-color: #272727 !important;
}
/* styles the autocomplete component */
.v-autocomplete__content.v-menu__content  {
  border-radius: 12px !important;
}
</style>