export default () => {
  const apiUrl = process.env.VUE_APP_LANGCHAIN_API
  const options = {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    withCredentials: false,
  }

  return {
    request: async (path, body) => {
      const response = await fetch(`${apiUrl}/${path}`, {
        ...options,
        body: JSON.stringify(body)
      })
      const { result } = await response.json()
      return result
    },
  }
}