var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-autocomplete',{staticClass:"clickable v-input--is-focused",staticStyle:{"border-radius":"12px"},attrs:{"multiple":"","filled":"","clearable":"","small-chips":"","hide-details":"","counter":"5","items":['question_gen', 'math_chat'],"placeholder":_vm.$t('labelTools'),"data-cy":"persona-tools-input"},on:{"change":_vm.setTools},scopedSlots:_vm._u([{key:"item",fn:function(data){return [[_c('v-list-item-content',{attrs:{"data-cy":("persona-tools-" + (data.item) + "-option")}},[_vm._v(" "+_vm._s(_vm.toolNames[data.item])+" ")])]]}},{key:"selection",fn:function(ref){
var item = ref.item;
var select = ref.select;
var selected = ref.selected;
return [_c('v-chip',{key:JSON.stringify(item),class:{
          'white white--text': _vm.$vuetify.theme.dark,
          'secondary secondary--text': !_vm.$vuetify.theme.dark
        },staticStyle:{"border-radius":"8px !important"},attrs:{"label":"","outlined":"","small":"","input-value":selected},on:{"click":select}},[_vm._v(" "+_vm._s(_vm.toolNames[item])+" ")])]}}]),model:{value:(_vm.tools),callback:function ($$v) {_vm.tools=$$v},expression:"tools"}}),_c('div',{staticClass:"my-4"}),_c('v-expand-transition',{attrs:{"hide-on-leave":""}},[(_vm.tools.length > 0)?_c('div',[_c('v-btn',{staticClass:"hs-rounded-12",attrs:{"color":"primary","elevation":"0"}},[_vm._v(" choose tool "),_c('v-icon',{attrs:{"right":""}},[_vm._v(" "+_vm._s(_vm.mdiChevronDown)+" ")])],1),_c('div',{staticClass:"my-3"}),_c('v-card',{staticStyle:{"border-radius":"12px","background-color":"transparent"},attrs:{"outlined":""}},[_c('v-container',[_c('PersonaParams',{attrs:{"enable":true,"defaultParams":(function () {
              var defParams = _vm.persona.tools.filter(function (ref) {
                      var type = ref.type;

                      return type === 'question_gen';
              })
              if (defParams.length > 0) { defParams = defParams[0].params ? defParams[0].params : {} }
              return defParams
            })()},on:{"setParams":function($event){return _vm.$emit('setParams', $event)}}})],1)],1)],1):_vm._e()])],1)}
var staticRenderFns = []

export { render, staticRenderFns }