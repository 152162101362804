var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('div',{staticClass:"text-h5 px-1"},[_vm._v(" Sources and Files ")]),_c('div',{class:{
    'text-body-2 grey--text px-1': true,
    'text--darken-3': !_vm.$vuetify.theme.dark
  }},[_vm._v(" Add files and a folder to your persona. With sources, you can build Personas to answer queries about your class, help you process complex data, or chat about a book. ")]),_c('div',{staticClass:"my-2"}),_c('v-row',[_c('v-col',{attrs:{"cols":"6"}},[(_vm.myFolders.slice(1).length > 0)?_c('v-select',{staticStyle:{"border-radius":"12px"},attrs:{"items":_vm.myFolders.slice(1).filter(function (ref) {
    var persona = ref.persona;

    return !persona;
}),"item-text":function (ref) {
    var name = ref.name;

    return name;
},"item-value":function (ref) {
    var _id = ref._id;

    return _id;
},"filled":"","clearable":"","hide-details":"","color":_vm.$vuetify.theme.dark ? 'white' : '',"label":"Reference Folder"},model:{value:(_vm.referenceFolder),callback:function ($$v) {_vm.referenceFolder=$$v},expression:"referenceFolder"}}):_c('div',[_c('v-list-item',{staticClass:"hs-rounded-list-item",on:{"click":function($event){return _vm.$router.push('/folders')}}},[_c('v-list-item-content',[_c('v-list-item-title',[_vm._v(" Create Reference Folder ")]),_c('v-list-item-subtitle',[_vm._v(" Click to go to Folder Manager ")])],1),_c('v-list-item-action',[_c('v-btn',{attrs:{"icon":""}},[_c('v-icon',[_vm._v(" "+_vm._s(_vm.mdiFolderArrowRight)+" ")])],1)],1)],1)],1)],1),_c('v-col',{attrs:{"cols":"6"}},[(_vm.currentContents.length > 0 && !_vm.showAddContent)?_c('div',[_c('v-card',{class:{
            'grey lighten-3': !_vm.$vuetify.theme.dark,
            'grey darken-3': _vm.$vuetify.theme.dark,
            'hs-rounded-12': true
          },attrs:{"elevation":"0"}},[_c('v-list-item',[_c('v-list-item-content',[_vm._v(" "+_vm._s(_vm.currentContents.length)+" content(s) ")]),_c('v-list-item-action',{staticStyle:{"display":"flex","flex-direction":"row"}},[_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
          var on = ref.on;
return [_c('v-btn',_vm._g({attrs:{"small":"","icon":""},on:{"click":function($event){return _vm.$router.push(("/folders?id=" + (_vm.personaFolder.id)))}}},on),[_c('v-icon',[_vm._v(" "+_vm._s(_vm.mdiFolderArrowRight)+" ")])],1)]}}],null,false,3720099611)},[_c('span',[_vm._v("Go to persona folder")])]),_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
          var on = ref.on;
return [_c('v-btn',_vm._g({attrs:{"small":"","icon":""},on:{"click":function($event){_vm.showAddContent = true}}},on),[_c('v-icon',[_vm._v(" "+_vm._s(_vm.mdiPlus)+" ")])],1)]}}],null,false,350541468)},[_c('span',[_vm._v("Add files")])])],1)],1)],1)],1):_c('v-file-input',{class:{
          'clickable': true,
          'hs-rounded-12': !_vm.loadingFiles,
          'hs-rounded-loading-bottom': _vm.loadingFiles
        },attrs:{"accept":".pdf","label":"Additional files","filled":"","multiple":"","loading":_vm.loadingFiles,"hide-details":"","prepend-inner-icon":_vm.mdiPaperclip,"prepend-icon":null},model:{value:(_vm.files),callback:function ($$v) {_vm.files=$$v},expression:"files"}})],1)],1),(false)?_c('div',[_c('div',{staticClass:"text-h5 px-1"},[_vm._v(" Links and Media ")]),_c('div',{class:{
      'text-body-2 grey--text px-1': true,
      'text--darken-3': !_vm.$vuetify.theme.dark
    }},[_vm._v(" You can add links to YouTube videos, news websites, papers, etc. Or you can add images, videos and audios to make your AI multimodal! ")]),_c('v-row',[_c('v-col',{attrs:{"cols":"6"}},[_c('v-text-field',{staticClass:"hs-rounded-12",attrs:{"filled":"","hide-details":"","placeholder":"Additional Links (e.g., YouTube)","prepend-inner-icon":_vm.mdiLink},on:{"change":_vm.addLink},model:{value:(_vm.link),callback:function ($$v) {_vm.link=$$v},expression:"link"}}),_c('div',{staticClass:"my-1"}),_c('v-btn',{staticClass:"px-2",staticStyle:{"border-radius":"8px"},attrs:{"text":"","small":""},on:{"click":function($event){_vm.showLinks = !_vm.showLinks}}},[_vm._v(" links "),_c('v-icon',{attrs:{"small":"","right":""}},[_vm._v(" "+_vm._s(_vm.showLinks ? _vm.mdiChevronUp: _vm.mdiChevronDown)+" ")])],1)],1),_c('v-col',{attrs:{"cols":"6"}},[_c('v-file-input',{staticClass:"clickable hs-rounded-12",attrs:{"accept":".jpg,.jpeg,.png","label":"Media","filled":"","multiple":"","hide-details":"","prepend-inner-icon":_vm.mdiMultimedia,"prepend-icon":null}}),_c('div',{staticClass:"my-1"}),_c('v-btn',{staticClass:"px-2",staticStyle:{"border-radius":"8px"},attrs:{"text":"","small":""},on:{"click":function($event){_vm.showMedia = !_vm.showMedia}}},[_vm._v(" media "),_c('v-icon',{attrs:{"small":"","right":""}},[_vm._v(" "+_vm._s(_vm.showMedia ? _vm.mdiChevronUp : _vm.mdiChevronDown)+" ")])],1)],1)],1)],1):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }