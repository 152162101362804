<template>
  <v-container class="py-2 pb-0 pr-10 pl-6" >
    <v-row
      align="center"
      justify="start"
      class="px-3 pl-0"
    >
      <v-btn
        text
        style="border-radius: 12px;"
        @click="routeBack"
      >
        <v-icon left>
          {{ mdiKeyboardBackspace }} 
        </v-icon>
        {{ $t('goBack') }}
      </v-btn>

      <v-spacer></v-spacer>

      <v-btn
        large
        disabled
        elevation="0"
        :class="{ 'grey lighten-2': !$vuetify.theme.dark }"
        style="border-radius: 12px;"
        data-cy="persona-generator-button"
      >
        {{ $t('autoGen') }}
        <v-icon right>
          {{ mdiShimmer }}
        </v-icon>
      </v-btn> 

      <div class="mx-1"></div>

      <v-btn
        elevation="0"
        color="primary"
        :loading="loading"
        style="border-radius: 12px; height: 40px;"
        @click="$emit('save')"
        data-cy="persona-save-button"
      >
        {{ $t('save') }} persona
      </v-btn>
    </v-row>

    <v-divider inset class="mt-2"></v-divider>
  </v-container>
</template>

<script>
import { Select } from '@components'
import {
  mdiArrowLeft,
  mdiShimmer,
  mdiDramaMasks,
  mdiKeyboardBackspace,
  mdiTag
} from '@mdi/js'
import { mapGetters } from 'vuex'

export default {
  components: {
    Select
  },

  props: {
    create: {
      type: Boolean,
      default: false
    },
    loading: {
      type: Boolean,
      default: false
    }
  },

  data: () => ({
    mdiTag,
    mdiDramaMasks,
    mdiKeyboardBackspace,
    mdiShimmer,
    mdiArrowLeft,
    personaTag: 'All personas',
    onBoardingOpen: false,
  }),

  computed: {
    ...mapGetters({
      pendingOnBoarding: 'pendingOnBoarding'
    }),
    slicedText() {
      return this.personaTag.length > 15 ? `${this.personaTag.slice(0, 6)}...` : this.personaTag;
    }
  },

  methods: {
    routeBack() {
      this.$router.go(-1)
    }
  }
}
</script>