<template>
  <v-card
    outlined
    style="border-radius: 12px; background-color: transparent;"
  >
    <v-container>
      <v-row justify="space-between" class="px-4">
        <div style="max-width: 25%;">
          <Select
            :items="roles"
            :itemSelected="role"
            :valueSelector="true"
            @chosenIndex="setRole"
            dataCy="role-select"
          ></Select>
        </div>

        <div>
          <v-btn
            v-if="index === 0"
            color="primary"
            elevation="0"
            style="border-radius: 12px;"
            @click="$emit('addRole')"
            class="mr-2"
            data-cy="add-role-button"
          >
            add another role
          </v-btn>

          <v-btn
            :disabled="index === 0 && lenRoles <= 1"
            icon
            style="background-color: rgba(183, 28, 28, 0.1);"
            color="danger"
            @click="$emit('remove')"
          >
            <v-icon>
              {{ mdiClose }}
            </v-icon>
          </v-btn>
        </div>
      </v-row>

      <v-row justify="center">
        <v-col cols="12">
          <div class="my-4"></div>
          <RoleForm
            :currentRole="currentRole"
            :role="role.toLowerCase()"
            @setRoles="setRoles"
          />
        </v-col>
      </v-row>
    </v-container>
  </v-card>
</template>

<script>
import RoleForm from './RoleForm.vue'
import { Select } from '@components'
import { mdiClose } from '@mdi/js'

export default {
  components: {
    RoleForm,
    Select
  },

  props: {
    index: {
      type: Number,
      default: 0
    },
    lenRoles: {
      type: Number,
      default: 0
    },
    currentRole: {
      type: Object,
      default: () => ({})
    }
  },

  created() {
    if (this.currentRole.role)
      this.role = this.currentRole.role
  },

  methods: {
    setRole(index) {
      this.role = this.roles[index].value
      this.$emit('setRole', { role: this.role })
    },

    setRoles(data) {
      this.$emit('setRole', data)
    }
  },

  data() {
    return {
      mdiClose,
      role: 'freestyle',
      roles: [{
        content: this.$tc('freestyle', 1),
        value: 'freestyle'
      }, {
        content: this.$tc('companion', 1),
        value: 'companion'
      }, {
        content: this.$tc('tutor', 1),
        value: 'tutor'              
      }, {
        content: this.$tc('assistant', 1),
        value: 'assistant'
      }]
    }
  }
}
</script>