<template>
  <div>
    <div class="text-h5 px-1">
      Sources and Files
    </div>
    <div :class="{
      'text-body-2 grey--text px-1': true,
      'text--darken-3': !$vuetify.theme.dark
    }">
      Add files and a folder to your persona. With sources, you can build Personas to answer queries about your class, help you process complex data, or chat about a book.
    </div>

    <div class="my-2"></div>

    <v-row>
      <v-col cols="6">
        <!-- We discard "My Library" and Persona folders -->
        <v-select
          v-if="myFolders.slice(1).length > 0"
          v-model="referenceFolder"
          :items="myFolders.slice(1).filter(({ persona }) => !persona)"
          :item-text="({name}) => name"
          :item-value="({_id}) => _id"
          filled
          clearable
          hide-details
          :color="$vuetify.theme.dark ? 'white' : ''"
          style="border-radius: 12px"
          label="Reference Folder"
        />
        <div v-else>
          <v-list-item
            class="hs-rounded-list-item"
            @click="$router.push('/folders')"
          >
            <v-list-item-content>
              <v-list-item-title>
                Create Reference Folder
              </v-list-item-title>

              <v-list-item-subtitle>
                Click to go to Folder Manager
              </v-list-item-subtitle>
            </v-list-item-content>

            <v-list-item-action>
              <v-btn icon>
                <v-icon>
                  {{ mdiFolderArrowRight }}
                </v-icon>
              </v-btn>
            </v-list-item-action>
          </v-list-item>
        </div>                  
      </v-col>
      <v-col cols="6">
        <div v-if="currentContents.length > 0 && !showAddContent">
          <v-card
            elevation="0"
            :class="{
              'grey lighten-3': !$vuetify.theme.dark,
              'grey darken-3': $vuetify.theme.dark,
              'hs-rounded-12': true
            }"
          >
            <v-list-item>
              <v-list-item-content>
                {{ currentContents.length }} content(s)
              </v-list-item-content>
              <v-list-item-action style="display: flex; flex-direction: row;">
                <v-tooltip top>
                  <template v-slot:activator="{ on }">
                    <v-btn
                      v-on="on"  
                      small
                      icon
                      @click="$router.push(`/folders?id=${personaFolder.id}`)"
                    >
                      <v-icon>
                        {{ mdiFolderArrowRight }}
                      </v-icon>
                    </v-btn>
                  </template>
                  <span>Go to persona folder</span>
                </v-tooltip>

                <v-tooltip top>
                  <template v-slot:activator="{ on }">
                    <v-btn
                      v-on="on"
                      small
                      icon
                      @click="showAddContent = true"
                    >
                      <v-icon>
                        {{ mdiPlus }}
                      </v-icon>
                    </v-btn>
                  </template>
                  <span>Add files</span>
                </v-tooltip>
              </v-list-item-action>
            </v-list-item>
          </v-card>
        </div>

        <v-file-input
          v-else  
          v-model="files"
          accept=".pdf"
          label="Additional files"
          filled
          multiple
          :loading="loadingFiles"
          hide-details
          :prepend-inner-icon="mdiPaperclip"
          :prepend-icon="null"
          :class="{
            'clickable': true,
            'hs-rounded-12': !loadingFiles,
            'hs-rounded-loading-bottom': loadingFiles
          }"
        ></v-file-input>
      </v-col>
    </v-row>

    <div v-if="false">
      <div class="text-h5 px-1">
        Links and Media
      </div>
      <div :class="{
        'text-body-2 grey--text px-1': true,
        'text--darken-3': !$vuetify.theme.dark
      }">
        You can add links to YouTube videos, news websites, papers, etc. Or you can add images, videos and audios to make your AI multimodal!
      </div>

      <v-row>
        <v-col cols="6">
          <v-text-field
            v-model="link"
            filled
            hide-details
            class="hs-rounded-12"
            placeholder="Additional Links (e.g., YouTube)"
            :prepend-inner-icon="mdiLink"
            @change="addLink"
          ></v-text-field>

          <div class="my-1"></div>

          <v-btn
            text
            small
            class="px-2"
            style="border-radius: 8px;"
            @click="showLinks = !showLinks"
          >
            links
            <v-icon small right>
              {{ showLinks ? mdiChevronUp: mdiChevronDown }}
            </v-icon>
          </v-btn>
        </v-col>

        <v-col cols="6">
          <v-file-input
            accept=".jpg,.jpeg,.png"
            label="Media"
            filled
            multiple
            hide-details
            :prepend-inner-icon="mdiMultimedia"
            :prepend-icon="null"
            class="clickable hs-rounded-12"
          ></v-file-input>

          <div class="my-1"></div>
          
          <v-btn
            text
            small
            class="px-2"
            style="border-radius: 8px;"
            @click="showMedia = !showMedia"
          >
            media
            <v-icon small right>
              {{ showMedia ? mdiChevronUp : mdiChevronDown }}
            </v-icon>
          </v-btn>
        </v-col>
      </v-row>
    </div>
  </div>
</template>

<script>
import { validateYoutubeUrl } from '@utils'
import { mapGetters } from 'vuex'
import {
  mdiChevronUp,
  mdiChevronDown,
  mdiPaperclip, 
  mdiLink,
  mdiMultimedia,
  mdiPlus,
  mdiFolderArrowRight
} from '@mdi/js'
import { MediaURL } from '@components'
import API from '@api'

export default {
  props: {
    currentContents: {
      type: Array,
      default: () => ([])
    },
    personaFolder: {
      type: Object,
      default: () => ({})
    },
    defaultReference: {
      type: String,
      default: ''
    },
    defaultContents: {
      type: Array,
      default: () => ([])
    }
  },

  created () {
    this.getMediaUrl = MediaURL.getMediaUrl.bind(this);
    this.getPdfTranscription = MediaURL.getPdfTranscription.bind(this);
    this.createPDFThumbnail = MediaURL.createPDFThumbnail.bind(this);
    this.saveContent = MediaURL.saveContent.bind(this);
  },
 
  data: () => ({
    mdiChevronUp,
    mdiChevronDown,
    mdiPaperclip,
    mdiLink,
    mdiPlus,
    mdiMultimedia,
    mdiFolderArrowRight,
    showLinks: false,
    showMedia: false,
    referenceFolder: '',
    showAddContent: false,
    loadingFiles: false,
    files: [],
    filesBuffer: [],
    lockFiles: false,
    link: '',
    links: []
  }),

  watch: {
    defaultReference(value) {
      setTimeout(() => {
        this.referenceFolder = value
      }, 100)
    },

    referenceFolder(value) {
      this.$emit('referenceFolder', value)
    },

  /**
   * Handle the uploaded files.
   * 
   * @param {Array<File>} files - The array of uploaded files.
   */
    async files(files) {
      if (!this.lockFiles) {
        this.filesBuffer = files.concat(this.filesBuffer)
        this.lockFiles = true

        this.loadingFiles = true

        let contentFiles = []
        // TODO: handle file type
        for (const file of files) {
          const {pdfDoc, transcription} = await this.getPdfTranscription(file)
          const transcriptionLen = transcription.map(({ textContent }) => textContent).join('').length

          console.log("Pdf transcription created")

          // If the transcription length is 0, then the there is no embedding to be done
          // Other than that we use the summarize function and the embedding to get a "title" and "about"
          let embeddingStatus, title, about;
          if (transcriptionLen === 0) {
            embeddingStatus = 'UNDEFINED'
          } else {
            title = await this.summarize(transcription, 'title')
            about = await this.summarize(transcription, 'about')

            console.log("title: ", title)
            console.log("about: ", about)
          }

          console.log("Pdf summarization done")

          // Saves pdf thumbnail to AWS S3 and retrieves its url
          const thumbnailObj = await this.createPDFThumbnail(file, pdfDoc, 1)
          const [thumbnailUrl] = await this.getMediaUrl([thumbnailObj], 'images/thumbnails')

          console.log("PDF thumbnail saved")

          // Saves pdf to AWS S3 and retrieves its url
          const pdfObj = {
            mediaSrc: URL.createObjectURL(file),
            filename: file.name,
            type: 'application/pdf'
          }
          const [pdfUrl] = await this.getMediaUrl([pdfObj], 'pdfs')

          console.log("Pdf url retrieved")

          // Creates the pdf and content records
          const content = await this.saveContent({
            pdfFile: file,
            user: this.user,
            sender: this.sender,
            title,
            about,
            thumbnailUrl,
            pdfUrl,
            embeddingStatus,
            transcriptionLen,
            currentFolder: this.personaFolder
          })

          // now, get the records returned by the content saving function
          // and add them to the folder
          contentFiles.push(content)
        }

        this.$emit('addContentFiles', contentFiles)

        this.loadingFiles = false

        this.files = [...this.filesBuffer]
      } else {
        this.lockFiles = false
      }
    }
  },

  computed: {
    ...mapGetters({
      user: 'user',
      myFolders: 'folders/myFolders',
      myProfile: 'profile/id',
      myName: 'profile/name',
      myAvatar: 'profile/avatar'
    }),

    sender() {
      return {
        profile: this.myProfile,
        name: this.myName,
        avatar: this.myAvatar
      }
    }
  },

  methods: {
    async summarize(transcription, type) {
      const response = await fetch(`${process.env.VUE_APP_LANGCHAIN_API}/hisolver_tasks`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        withCredentials: false,
        body: JSON.stringify({
          query: JSON.stringify(transcription.slice(0, 10)),
          model_number: 1,
          stream: false,
          task: `summarize_${type}`
        })
      })
      const jsonRes = await response.json()
      return jsonRes.result
    },

    async addLink(link) {
      this.link = ''
      // validateYoutubeUrl(link)
    }
  }
}
</script>

<style>
.hs-rounded-list-item.theme--light.v-list-item:hover::before,
.hs-rounded-list-item.theme--dark.v-list-item:hover::before {
  border-radius: 12px;
}
</style>