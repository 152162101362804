<template>
  <div>
    <v-autocomplete
      multiple
      filled
      clearable
      small-chips
      hide-details
      counter="5"
      :items="['question_gen', 'math_chat']"
      style="border-radius: 12px;"
      class="clickable v-input--is-focused"
      :placeholder="$t('labelTools')"
      v-model="tools"
      @change="setTools"
      data-cy="persona-tools-input"
    >
      <template v-slot:item="data">
        <template>
          <v-list-item-content :data-cy="`persona-tools-${data.item}-option`">
            {{ toolNames[data.item] }}
          </v-list-item-content>
        </template>
      </template>

      <template v-slot:selection="{ item, select, selected }">
        <v-chip
          label
          outlined
          small
          :class="{
            'white white--text': $vuetify.theme.dark,
            'secondary secondary--text': !$vuetify.theme.dark
          }"
          style="border-radius: 8px !important;"
          :key="JSON.stringify(item)"
          :input-value="selected"
          @click="select"
        >
          {{ toolNames[item] }}
        </v-chip>
      </template>
    </v-autocomplete>

    <div class="my-4"></div>

    <v-expand-transition hide-on-leave>
      <div v-if="tools.length > 0">
        <v-btn color="primary" class="hs-rounded-12" elevation="0">
          choose tool
          <v-icon right>
            {{ mdiChevronDown }}
          </v-icon>
        </v-btn>

        <div class="my-3"></div>

        <v-card
          outlined
          style="border-radius: 12px; background-color: transparent;"
        >
          <v-container>
            <PersonaParams
              :enable="true"
              :defaultParams="(() => {
                let defParams = persona.tools.filter(({ type }) => type === 'question_gen')
                if (defParams.length > 0) defParams = defParams[0].params ? defParams[0].params : {}
                return defParams
              })()"
              @setParams="$emit('setParams', $event)"
            />
          </v-container>
        </v-card>
      </div>
    </v-expand-transition>
  </div>
</template>

<script>
import PersonaParams from './PersonaParams'
import { mdiChevronDown } from '@mdi/js'

export default {
  components: {
    PersonaParams
  },

  props: {
    persona: {
      type: Object,
      default: () => ({})
    }
  },

  mounted() {
    setTimeout(() => {
      if (this.persona.tools)
        this.tools = this.persona.tools.map(({ type }) => type)
    }, 100)
  },

  data: () => ({
    mdiChevronDown,
    tools: [],
    toolNames: {
      'question_gen': 'Question generator',
      'math_chat': 'Math Chat'
    }
  }),

  methods: {
    setTools() {
      this.$emit('setTools', this.tools.map(toolName => {
        if (this.persona[toolName])
          return this.persona[toolName]
        else
          return { type: toolName }
      }))
    }
  }
}
</script>